import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SecurityService } from './security.service';

import { ToastrService } from 'ngx-toastr';

import { UserService } from 'src/app/shared/user/user.service';

@Component({
  selector: 'app-security',
  templateUrl: './security.component.html',
  styleUrls: ['./security.component.css']
})
export class SecurityComponent implements OnInit {
  
  form: FormGroup;

  spinnerLoading: boolean = false;

  constructor(private fb: FormBuilder, private securityService: SecurityService, private toastrService: ToastrService,
    private userService: UserService) {
    this.form = this.fb.group({
      oldPassword: [null, Validators.compose([Validators.required])],
      newPassword: [null, Validators.compose([Validators.required, Validators.minLength(8) ])],
      confirmPassword: [null, Validators.compose([ Validators.required, this.validateConfirmPassword.bind(this) ])]
    });
   }

   validateConfirmPassword( control: FormControl ) {
    
    if (control) {
      if (control.parent) {
        if (control.parent.controls['newPassword'].value) {
          if (control.value !== control.parent.controls['newPassword'].value ) {
            return {
              passwordMatchError: true
            }
          } else {
            return null;
          }
        }
      }
    }

  }

  ngOnInit(): void {
  }

  submit() {
    this.spinnerLoading = true;
    this.securityService.updatePassword(this.form.value).subscribe(
      (res: any) => {
        console.log(res);
        this.spinnerLoading = false;
        if (res.message === 'password_error') {
          this.toastrService.error("Erreur", "Mot de passe actuel incorrect");
        } else if (res.message === 'success') {
          this.toastrService.success("Succès", "Mot de passe mis à jour avec succès, vous devez vous reconnecter avec vos nouveaux paramètres.");
          this.userService.currentUser.next(null);
        }
      },
      (err) => {
        console.log(err);
        this.spinnerLoading = false;
      }
    )
  }

}
