export class User {

  public username: string;
  public password: string;
  public authToken: any;
  public role: any;
  public data: any;

  constructor(username: string = null, password: string = null, authToken: any = null,
   role: string = null, data: any = null) {
    this.username = username;
    this.password = password;
    this.authToken = authToken;
    this.role = role;
    this.data = data;
  }

}
