import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs/index';
import { ReportService } from '../report/report.service';

@Injectable()
export class ReportSackResolver implements Resolve<any> {

  constructor(private reportService: ReportService ) {
  }

  resolve(route: ActivatedRouteSnapshot,
          state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

    return this.reportService.reportSack;

  }

}
