import { Injectable } from '@angular/core';
import {User} from './model/user.model';
import {HttpClient, HttpErrorResponse, HttpResponse} from '@angular/common/http';
import { throwError } from 'rxjs';
import {Observable} from 'rxjs/index';
import { map, catchError } from 'rxjs/operators';
import {GlobalVariable} from '../../global';
import {Subject} from 'rxjs/index';

@Injectable()
export class UserService {

  public currentUser: Subject<User> = new Subject<User>();
  public currentUserData: User;
  public logOutSubject: Subject<any> = new Subject<any>();

  constructor(private http: HttpClient) {
    this.currentUser.subscribe(
      (user) => {
        if (user) {
          this.currentUserData = user;
        }
      }
    );
  }
  userExists(): boolean {
    const currentUser: any = window.localStorage.getItem('gcmUser');
    return (currentUser !== null);
  }
  getToken(): string {
    const currentUser: any = JSON.parse(window.localStorage.getItem('gcmUser'));
    return currentUser.authToken;
  }
  getCurrentUser(): any {
    return Object.assign({}, this.currentUserData);
  }

  getSociety() {
    return this.http.get(GlobalVariable.BASE_API_URL+'/logged-out/user/society')
    .pipe(
      map(
        (response: any) => { return response; }
      ),
      catchError(
        (error: HttpErrorResponse) => {
          console.log(error);
          return Observable.throw({
            code: error.status,
            content: JSON.parse(error.error).message
          });
        }
      )
    )  
  }

  showUser() {
    return this.http.get(GlobalVariable.BASE_API_URL+'/logged/user/show')
    .pipe(
      map(
        (response: any) => { return response; }
      ),
      catchError(
        (error: HttpErrorResponse) => {
          console.log(error);
          return Observable.throw({
            code: error.status,
            content: JSON.parse(error.error).message
          });
        }
      )
    )  
  }

}
