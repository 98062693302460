export const GlobalVariable = Object.freeze({
    // local
    // BASE_API_URL: 'http://localhost/sello',
    // online
    // BASE_API_URL: 'http://localhost/gcm/USERS',
    BASE_API_URL: 'https://api.dotcomteam.net/USERS',
    APP_ID: 'GCM_IOT_APP',
    API_MEDIA_URL: 'http://localhost/sello/medias',
    COUNTRY_PHONE_CODE: '228',
    COUNTRY_PHONE_LENGTH: 8,
    FIREBASE_SENDER_ID: '928745526831'
});
