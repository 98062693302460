import { Routes } from '@angular/router';

import { DashboardComponent } from '../../pages/dashboard/dashboard.component';
import { SecurityComponent } from 'src/app/pages/security/security.component';
import { ReportComponent } from 'src/app/pages/report/report.component';
import { UserConfigsResolver } from 'src/app/pages/report/user-configs.resolver';
import { ReportDetailsComponent } from 'src/app/pages/report-details/report-details.component';

import { ReportSackResolver } from 'src/app/pages/report-details/report-sack.resolver';

export const AdminLayoutRoutes: Routes = [
    { path: 'dashboard', component: DashboardComponent },
    { path: 'security', component: SecurityComponent },
    { path: 'report', component: ReportComponent, resolve: {
        configs: UserConfigsResolver
    }},
    { path: 'report-details', component: ReportDetailsComponent, resolve: {
        sack: ReportSackResolver
    }}
];
