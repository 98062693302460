import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { User } from '../../shared/user/model/user.model';
import { UserService } from 'src/app/shared/user/user.service';
import { Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit, OnDestroy {
  form: FormGroup;
  error: any = null;
  spinnerLoading = false;

  countries = [
    { lib: 'TOGO', code: '00228' },
    { lib: 'BENIN', code: '00229' }
  ];

  constructor(private fb: FormBuilder, private authService: AuthService, 
    private userService: UserService, private router: Router, private toastrService: ToastrService ) {
    this.form = this.fb.group({
      country: [null, Validators.compose([Validators.required])],
      countryCode: ['00228', Validators.compose([Validators.required])],
      phone : [null, Validators.compose([Validators.required])],
      password : [null, Validators.compose([Validators.required])],
    });

    this.form.controls['country'].valueChanges.subscribe(
      (value) => {
        console.log(value);
        //  const countryChoose = this.countries.filter((c) => { return c.code === value; });
        this.form.controls['countryCode'].setValue( value );
      }
    );

  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  submit() {
    this.error = null;
    this.spinnerLoading = true;
    this.authService.login(this.form.value).subscribe(
      (res: any) => {
        this.spinnerLoading = false;
        if (res.message === 'phone_error') {
          this.toastrService.error("Erreur", "Erreur numéro de téléphone");
          this.error = 'Erreur numéro de téléphone';
        } else if (res.message === 'password_error') {
          this.toastrService.error("Erreur", "Erreur mot de passe");
          this.error = 'Erreur mot de passe';
        } else if (res.message === 'success') {
          this.error = null;
           const user = new User(res.pseudo, null, res.token, null, {
             'lastname': res.lastname,
             'firstname': res.firstname
           });
          const gcmUser = window.localStorage.getItem('gcmUser');
          if (gcmUser) {
            window.localStorage.removeItem('gcmUser');
          }
          window.localStorage.setItem('gcmUser', JSON.stringify(user));
          this.userService.currentUser.next(user);
          this.router.navigate( ['/dashboard'] );
        }
      }, (err) => {
        this.toastrService.error("Erreur", "Une erreur s'est produite, réesseyez.");
        this.error = "Une erreur s'est produite, réesseyez.";
        this.spinnerLoading = false;
      }
    )
  }

}
