import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ReportService } from './report.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {

  nbrePostes = [
    4, 8, 12, 16
  ];
  form: FormGroup;
  error: any = null;
  spinnerLoading = false;
  postes: any  = [];
  consoles: any = [];
  societies: any = [];

  constructor(private fb: FormBuilder, private route: ActivatedRoute, 
    private reportService: ReportService, private router: Router ) {
    this.form = this.fb.group({
      date1: [null, Validators.compose([Validators.required])],
      date2: [null, Validators.compose([Validators.required, this.validateDate.bind(this)])],
      poste: [null, Validators.compose([ ])],
      console: [null, Validators.compose([ ])],
      society: [null, Validators.compose([ ])],
    });
   }

  
  validateDate( control: FormControl ) {
    
    if (control) {
      if (control.parent) {
        if (control.parent.controls['date1'].value) {
          const date2 = new Date(control.value); 
          const date1 = new Date(control.parent.controls['date1'].value);
          if (date2.getTime() < date1.getTime() ) {
            return {
              dateError: true
            }
          } else {
            return null;
          }
        }
      }
    }

  }


  ngOnInit(): void {
    this.route.data.subscribe(
      (data: any) => {
        const configs = data['configs'].configs;
        if (configs != null ) {
          this.postes = configs.postes;
          this.consoles = configs.consoles;
          this.societies = configs.societies;
        } 
      }
    );
  }

  submit() {
    console.log(this.form.value);
    this.reportService.getReport(this.form.value).subscribe(
      (res: any) => {
        console.log(res);
        this.reportService.reportSack = res;
        this.router.navigate( ['/report-details'] );
      },
      (err) => {
        console.log(err);
      }
    );
  }

}
