import {Injectable} from '@angular/core';
import {
  HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest,
  HttpResponse,
  HttpHeaders
} from '@angular/common/http';

import { throwError } from 'rxjs';
import {Observable} from 'rxjs/index';
import { map, catchError } from 'rxjs/operators';

import {UserService} from '../user/user.service';
import { AuthService } from './auth.service';
import {GlobalVariable} from '../../global';
import { Router } from '@angular/router';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {

  constructor(private userService: UserService, private authService: AuthService,
    private router: Router) { console.log('Request is intercepted successfully.'); }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      console.log('Interceptor');
      let appReq = req;
      appReq = appReq.clone({headers: req.headers.set('X-App-Id', GlobalVariable.APP_ID )
      });
      if (this.authService.isAuthenticated()) {
            appReq = appReq.clone({headers: req.headers
                .set('X-Auth-Token', this.userService.getToken())
                .set('X-App-Id', GlobalVariable.APP_ID )
            });
      }
        return next.handle(appReq).pipe(
            map((event: HttpEvent<any>) => {
                // this.utilsService.closeLoading();
                if (event instanceof HttpResponse) {
                    // console.log(event);
                    if (event.body.message) {
                        if (event.body.message === 'invalid-token') {
                            // this.utilsService.showSimpleAlert('Erreur', 'Votre connexion a expiré.');
                            this.userService.currentUser.next(null);
                            return null;
                        } else if (event.body.message === 'error_app') {
                            // this.utilsService.showSimpleAlert('Erreur', 'Ouuppsss, Une erreur s\'est produite');
                            this.userService.currentUser.next(null);
                            return null;
                        }
                    }
                    // this.errorDialogService.openDialog(event);
                }
                return event;
            }),
            catchError((err: any, caught: Observable<any>) => {
                // console.log(err);
                if (err.status === 0) {
                    //this.utilsService.showSimpleAlert('Erreur',
                      //  'Nous n\'arrivons pas à joindre le serveur. Vérifiez si' +
                      //  ' vous êtes bien connecté à Internet puis réesseyez, merci.');
                      this.userService.currentUser.next(null);
                      this.router.navigateByUrl('/login');
                } else if (err.status === 401 || err.status === 403) {
                    // this.utilsService.showSimpleAlert('Erreur', 'Votre connexion à expirer, vous devez vous reconnecter.');
                    // this.authService.authSubject.next(null);
                    this.router.navigateByUrl('/login');
                }
                return throwError(err);
            })
        );
    }


}
