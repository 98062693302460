import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpResponse, HttpHeaders} from '@angular/common/http';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import {GlobalVariable} from '../../global';
import { UserService } from '../user/user.service';

@Injectable()
export class AuthService {

  private loginUrl: string = GlobalVariable.BASE_API_URL + '/login.php';

  constructor(private http: HttpClient,
              private userService: UserService) {
      const userDataStored = window.localStorage.getItem('gcmUser');
      if (userDataStored === null) {
        this.userService.currentUser.next(null);
      } else {
        this.userService.currentUser.next(JSON.parse(userDataStored));
      }
  }

  login(credentials) {
    return this.http.post(this.loginUrl, credentials)
    .pipe(
      map(
        (res) => {
          return res;
        }
      ),
      catchError(
        (error: HttpErrorResponse) => {
          return throwError({
            code: error.status,
            content: JSON.parse(error.error)
          });
        }
      )
    );
  }

  logout() {
    return this.http.put(GlobalVariable.BASE_API_URL + '/auth/signout',{token: this.userService.getToken()})
    .pipe(
      map(
        (res) => {
          return res;
        }
      ),
      catchError(
        (error: HttpErrorResponse) => {
          return throwError({
            code: error.status,
            content: JSON.parse(error.error)
          });
        }
      )
    );
    
  }

  isAuthenticated(): boolean {
    return this.userService.userExists();
  }

}
