import { Component, OnInit } from '@angular/core';
import Chart from 'chart.js';

import * as moment from 'moment';
import { DashboardService } from './dashboard.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2
} from "../../variables/charts";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  public datasets: any;
  public data: any;
  public salesChart;
  public ordersChart;
  public clicked: boolean = true;
  public clicked1: boolean = false;


  colors = {
    gray: {
      100: '#f6f9fc',
      200: '#e9ecef',
      300: '#dee2e6',
      400: '#ced4da',
      500: '#adb5bd',
      600: '#8898aa',
      700: '#525f7f',
      800: '#32325d',
      900: '#212529'
    },
    theme: {
      'default': '#172b4d',
      'primary': '#5e72e4',
      'secondary': '#f4f5f7',
      'info': '#11cdef',
      'success': '#2dce89',
      'danger': '#f5365c',
      'warning': '#fb6340'
    },
    black: '#12263F',
    white: '#FFFFFF',
    transparent: 'transparent',
  };

  chartExample1 = {
    options: {
      scales: {
        yAxes: [{
          gridLines: {
            color: this.colors.gray[900],
            zeroLineColor: this.colors.gray[900]
          },
          ticks: {
            callback: function(value) {
              if (!(value % 10)) {
                return value;
              }
            }
          }
        }]
      }
    },
    data: {
      labels: ['May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      datasets: [{
        label: 'Performance',
        data: [0, 20, 10, 30, 15, 40, 20, 60, 60]
      }]
    }
  };
  
  chartExample2 = {
    options: {
      scales: {
        yAxes: [
          {
            ticks: {
              callback: function(value) {
                if (!(value % 10)) {
                  //return '$' + value + 'k'
                  return value;
                }
              }
            }
          }
        ]
      },
      tooltips: {
        callbacks: {
          label: function(item, data) {
            var label = data.datasets[item.datasetIndex].label || "";
            var yLabel = item.yLabel;
            var content = "";
            if (data.datasets.length > 1) {
              content += label;
            }
            content += yLabel;
            return content;
          }
        }
      }
    },
    data: {
      labels: ["Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      datasets: [
        {
          label: "Sales",
          data: [25, 20, 30, 22, 17, 29]
        }
      ]
    }
  };


  months = [
    {
      name: 'Janvier',
      value: 1
    },
    {
      name: 'Février',
      value: 2
    },
    {
      name: 'Mars',
      value: 3
    },
    {
      name: 'Avril',
      value: 4
    },
    {
      name: 'Mai',
      value: 5
    },
    {
      name: 'Juin',
      value: 6
    },
    {
      name: 'Juillet',
      value: 7
    },
    {
      name: 'Août',
      value: 8
    },
    {
      name: 'Septembre',
      value: 9
    },
    {
      name: 'Octobre',
      value: 10
    },
    {
      name: 'Novembre',
      value: 11
    },
    {
      name: 'Décembre',
      value: 12
    }
  ];

  form: FormGroup;

  currentMonth: any;

  loadCharts: boolean = false;

  constructor(private dashboardService: DashboardService, 
    private fb: FormBuilder ) {
    this.form = this.fb.group({
      month: [null ,
        Validators.compose ( [ Validators.required ] ),
        Validators.composeAsync([])
      ]
    });  
    var monthBegin = moment().startOf('month').toDate();
    this.currentMonth = monthBegin.getMonth() + 1;

    console.log(this.currentMonth);

    this.form.controls['month'].setValue(this.currentMonth);

    this.form.controls['month'].valueChanges.subscribe(
      (value) => {
        this.updateCharts(value);
      }
    );

  }

  ngOnInit() {
    
    this.datasets = [
      [0, 20, 10, 30, 15, 40, 20, 60, 60],
      [0, 20, 5, 25, 10, 30, 15, 40, 40]
    ];

    this.data = this.datasets[0];

    var monthBegin = moment().startOf('month').toDate();
    var monthEnd = moment().endOf('month').toDate();
    this.loadCharts = true;
    this.dashboardService.getChartData({
      monthBegin: monthBegin.getDate(),
      monthEnd:  monthEnd.getDate(),
      currentYear: monthBegin.getFullYear(),
      currentMonth: monthBegin.getMonth() + 1
    }).subscribe(
      (response: any) => {

        this.chartExample2.data = {
          labels: response.dayData ,
          datasets: [
            {
              label: "Gain",
              data: response.dayCosts
            }
          ]
        };

        this.chartExample1.data = {
          labels: response.dayData,
          datasets: [{
            label: 'Gain',
            data: response.dayCosts
          }]
        };

        var chartOrders = document.getElementById('chart-orders');

        parseOptions(Chart, chartOptions());

        this.ordersChart = new Chart(chartOrders, {
          type: 'bar',
          options: this.chartExample2.options,
          data: this.chartExample2.data
        });



        var chartSales = document.getElementById('chart-sales');

        this.salesChart = new Chart(chartSales, {
          type: 'line',
          options: this.chartExample1.options,
          data: this.chartExample1.data
        });

        this.loadCharts = false;
       
      }, (err) => {
        this.loadCharts = false;
      }
    );

    
  }

  updateCharts(monthSelected: number) {
    this.loadCharts = true;

    var monthBegin = moment().startOf('month').toDate();

    var startDate = moment([monthBegin.getFullYear(), (monthSelected-1)]);

    // Clone the value before .endOf()
    var endDate = moment(startDate).endOf('month');

    monthBegin = startDate.toDate();
    var monthEnd = endDate.toDate();

    this.dashboardService.getChartData({
      monthBegin: monthBegin.getDate(),
      monthEnd:  monthEnd.getDate(),
      currentYear: monthBegin.getFullYear(),
      currentMonth: monthSelected
    }).subscribe(
      (response: any) => {

        this.salesChart.data.labels = response.dayData;
        this.ordersChart.data.labels = response.dayData;
        this.salesChart.data.datasets[0].data = response.dayCosts;
        this.ordersChart.data.datasets[0].data = response.dayCosts;
        this.salesChart.update();
        this.ordersChart.update();
        this.loadCharts = false;

      });
    
  }





  public updateOptions() {
    this.salesChart.data.datasets[0].data = this.data;
    this.salesChart.update();
  }

}
