import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ClipboardModule } from 'ngx-clipboard';

import { AdminLayoutRoutes } from './admin-layout.routing';
import { DashboardComponent } from '../../pages/dashboard/dashboard.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { HttpInterceptorService } from 'src/app/shared/auth/http-interceptor.service';


import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import { DashboardService } from 'src/app/pages/dashboard/dashboard.service';

import { SecurityComponent } from 'src/app/pages/security/security.component';
import { ReportComponent } from 'src/app/pages/report/report.component';
import { ReportDetailsComponent } from 'src/app/pages/report-details/report-details.component';
import { ReportService } from 'src/app/pages/report/report.service';
import { UserConfigsResolver } from 'src/app/pages/report/user-configs.resolver';
import { ReportSackResolver } from 'src/app/pages/report-details/report-sack.resolver';
import { SecurityService } from 'src/app/pages/security/security.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(AdminLayoutRoutes),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    ClipboardModule,
    ToastrModule.forRoot(),
    NgxDatatableModule
  ],
  declarations: [
    DashboardComponent,
    SecurityComponent,
    ReportComponent,
    ReportDetailsComponent
  ], 
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService ,
      multi: true,
    },
    DashboardService,
    ReportService,
    UserConfigsResolver,
    ReportSackResolver,
    SecurityService
  ]
})

export class AdminLayoutModule {}
