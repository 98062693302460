import { Injectable } from '@angular/core';
// import {User} from './model/user.model';
import {HttpClient, HttpErrorResponse, HttpResponse} from '@angular/common/http';
import { throwError } from 'rxjs';
import {Observable} from 'rxjs/index';
import { map, catchError } from 'rxjs/operators';
import {GlobalVariable} from '../../global';
import {Subject} from 'rxjs/index';

@Injectable()
export class SecurityService {

  constructor(private http: HttpClient) {
  }

  updatePassword(data) {
    return this.http.put(GlobalVariable.BASE_API_URL+'/updatePassword.php', data)
    .pipe(
      map(
        (response: any) => { return response; }
      ),
      catchError(
        (error: HttpErrorResponse) => {
          console.log(error);
          return Observable.throw({
            code: error.status,
            content: JSON.parse(error.error).message
          });
        }
      )
    )  
  }


}
