import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import * as jsPDF from 'jspdf'
import * as HTMLtoPDF from 'html2pdf.js';


@Component({
  selector: 'app-report-details',
  templateUrl: './report-details.component.html',
  styleUrls: ['./report-details.component.css']
})
export class ReportDetailsComponent implements OnInit {
  report: any = null;
  loading:boolean = false;
  constructor(private route: ActivatedRoute, private router: Router ) { } 

  ngOnInit(): void {
    this.route.data.subscribe(
      (data: any) => {
        const sack = data['sack'];
        console.log(sack);
        if (sack !== null && sack !== undefined) {
          this.report = sack;
        } else {
          this.router.navigate( ['/report'] );
        }
      }
    );
  }


  exportToPdf() {
    this.JsToPdf(this.report.sells, false);
  }

  JsToPdf(approDataContent, includeImge=false) {
        if (approDataContent) {
          if (approDataContent.length) {
          const reportData = [
              {
                field: 'Date de début',
                value: this.report.beginDate
              },
              {
                field: 'Date de fin',
                value: this.report.endDate
              },
              {
                field: 'Nombre arrêté',
                value: this.report.sellsStopped.length
              },
              {
                field: 'Total vendu',
                value: this.report.totalSells
              },
              {
                field: 'Total arrêté',
                value: this.report.totalSellsStopped
              },
              {
                field: 'Total net',
                value: this.report.totalSells - this.report.totalSellsStopped 
              }

          ];
          this.loading = true;
          // let content =  '<!doctype html>'+
          //   '<html>'+
          //   '<head>'+
          //   '<meta charset="utf-8"> <style>' +
          let content = '<style>' + 'table tr:nth-child(2n){background-color: rgba(240,240,240,1)} ' +
            /*'table tr:nth-child(16){border-top: solid 1px #000000}' +*/
            'table tr td:last-child{border-right: solid 1px #000000 !important;}' +
            'table thead th:last-child{border-right: solid 1px #000000 !important;}' +
            '.total-table tr:first-child td{border-top: solid 1px #000000 !important;}' +
            /*table thead th:nth-child(1){width: 130px !important;}' +
            'table thead th:nth-child(5){width: 110px !important;}' +*/
            '</style>' +
            // '</head>' +
            // '<body>' +
            '<div class="print-header-content" style="width: 100%; padding: 0; margin: 0; float: left;">\n' +
            '    <div class="print-logo" style="margin: 0px 0; padding: 0; float: left;">\n' +
            '      <img src="assets/img/logo.JPG" alt="logo-gcm" style="width: 60px;">\n' +
            '    </div>\n' +
            '    <div class="print-header-text" style="margin: 0; padding: 0; float: right;">\n' +
            '      <ul style="padding: 0; margin: 0 20px 0 0; float: left;">\n' +
            '        <li style="display: inline-block; list-style-type: none; color: #000000;"><b>Tél :</b></li>\n' +
            '        <li style="display: inline-block; list-style-type: none; color: #000000;">+ 228 90 25 32 51 / 91 64 69 85</li>\n' +
            '      </ul>\n' +
            '      <ul style="padding: 0; margin: 0 20px 0 0; float: left;">\n' +
            '        <li style="display: inline-block; list-style-type: none; color: #000000;"><b>Site web :</b></li>\n' +
            '        <li style="display: inline-block; list-style-type: none; color: #000000;">gcm.com</li>\n' +
            '      </ul>\n' +
            '      <ul style="padding: 0; margin: 0; float: left;">\n' +
            '        <li style="display: inline-block; list-style-type: none; color: #000000;"><b>Email :</b></li>\n' +
            '        <li style="display: inline-block; list-style-type: none; color: #000000;">contact@gcm.com</li>\n' +
            '      </ul>\n' +
            '    </div>\n' +
            '</div>';

            content = content + '<table class="total-table" style="border-collapse: collapse; width: 49%; margin: 8px 2% 30px 0; float: left;">';
          
            reportData.forEach(
              (line, index) => {
                  content = content + '<tr>' + '<td style="width: 200px; border: solid 1px #000000; padding: 5px 8px; ' +
                  'text-align: left; border-top: none; border-right: none; font-weight: 600; background-color: rgba(240, 240, 240, 1);">' +
                  line.field + '</td>' + '<td style="background-color: #ffffff; border: solid 1px #000000; padding: 5px 8px; text-align: left;' +
                  'border-top: none; border-right: none;">' + line.value + '</td>' + '</tr>';
              }
            ); 
        
            content = content + '</table>';
            
            '<div style="width: 100%; padding: 0; margin: 0;">' + '<h1 style="width: 100%; text-align: center; font-size: 1.6em;' +
            ' float: left; color: #000000; ' + 'font-weight: normal; margin: 10.5px 0; padding: 0;">' +
            '<b>RAPPORT DES VENTES</b></h1>'+
            '</div>';
    
          content = content + '<table style="border-collapse: collapse; width: 97%; margin-top: 0px; float: left;">';
    
          let page = 0;
    
          content = content + '<tbody>';
          approDataContent.forEach(
              (line, index) => {
                content = content + '<tr>' + '<td style="border: solid 1px #000000; padding: 3px 5px; text-align: left;' +
                            'border-top: none; border-right: none;">'
                            + line.sell.poste.name + '</td>' + 
                            '<td style="border: solid 1px #000000; padding: 3px 5px; text-align: left;' +
                            'border-top: none; border-right: none;">'
                            + line.sell.console.name + '</td>' + 
                            '<td style="border: solid 1px #000000; padding: 3px 5px; text-align: left;' +
                            'border-top: none; border-right: none;">'
                            + line.sell.tarif.cost + '</td>' + 
                            '<td style="border: solid 1px #000000; padding: 3px 5px; text-align: left;' +
                            'border-top: none; border-right: none;">'
                            + (line.sell.wasStop === true ? 'Oui' : 'Non') + '</td>'+ 
                            '<td style="border: solid 1px #000000; padding: 3px 5px; text-align: left;' +
                            'border-top: none; border-right: none;">'
                            + (line.sell.remaining_time.hour || '00') + ':' + 
                            (line.sell.remaining_time.minute || '00') + ':' + (line.sell.remaining_time.second || '00') + '</td>'+ 
                            '<td style="border: solid 1px #000000; padding: 3px 5px; text-align: left;' +
                            'border-top: none; border-right: none;">'
                            + line.society + '</td>' + 
                            '<td style="border: solid 1px #000000; padding: 3px 5px; text-align: left;' +
                            'border-top: none; border-right: none;">'
                            + line.sell.created_at + '</td>';
                content = content + '</tr>';

                const fisrtPageCount = 25;
                const restPageCount = 25;
                const k = 29;
        
                if ( (index + 1) === fisrtPageCount) {
                  page = page + 1;
                  content = content + '<tr style="background-color: transparent; border-bottom: solid 1px #000000;"><td colspan="100" ' +
                    'class="html2pdf__page-break" style="background-color: transparent; border-right: none !important;"></td></tr>';
                }
                if ( ((index + 1) % k === restPageCount) && (index > restPageCount) ) {
                  page = page + 1;
                  content = content + '<tr style="background-color: transparent; border-bottom: solid 1px #000000;"><td colspan="100" ' +
                    'class="html2pdf__page-break" style="background-color: transparent; border-right: none !important;"></td></tr>';
                }
              }
            );
          
          content = content + '</tbody>';
    
          content = content + '<thead>';
    
          content = content + '<tr><th style="background-color: rgba(240, 240, 240, 1); border: solid 1px #000000; border-right: none; ' +
            ' text-align: center;' +
            'padding: 2px 4px; text-align: left;">' + 'Poste' + '</th>' + 
            '<th style="background-color: rgba(240, 240, 240, 1); border: solid 1px #000000; border-right: none; ' +
            ' text-align: center;' +
            ' padding: 2px 4px; text-align: left;">' + 'Console' + '</th>' +
            '<th style="background-color: rgba(240, 240, 240, 1); border: solid 1px #000000; border-right: none; ' +
            ' text-align: center;' +
            ' padding: 2px 4px; text-align: left;">' + 'Tarif' + '</th>' +
            '<th style="background-color: rgba(240, 240, 240, 1); border: solid 1px #000000; border-right: none; ' +
            ' text-align: center;' +
            ' padding: 2px 4px; text-align: left;">' + 'Temps arrêté' + '</th>' +
            '<th style="background-color: rgba(240, 240, 240, 1); border: solid 1px #000000; border-right: none; ' +
            ' text-align: center;' +
            ' padding: 2px 4px; text-align: left;">' + 'Temps restant' + '</th>'+
            '<th style="background-color: rgba(240, 240, 240, 1); border: solid 1px #000000; border-right: none; ' +
            ' text-align: center;' +
            ' padding: 2px 4px; text-align: left;">' + 'Société' + '</th>'+
            '<th style="background-color: rgba(240, 240, 240, 1); border: solid 1px #000000; border-right: none; ' +
            ' text-align: center;' +
            ' padding: 2px 4px; text-align: left;">' + 'Date' + '</th>';
          
          content = content + '</tr>';
    
          content = content + '</thead>';  
    
          content = content + '</table>';
    
          var style = "<style>";
          style = style + "table {width: 100%; height: 100%; font: 17px Calibri;}";
          style = style + "table, th, td {border: solid 1px #DDD; border-collapse: collapse;";
          style = style + "padding: 2px 3px;text-align: center;}";
          style = style + "</style>";

          // content = style + content;

          HTMLtoPDF(content, {
            margin:       1,
            filename:    'rapports_ventes_' + Date.now() + '.pdf',
            image:        { type: 'jpeg', quality: 1 },
            html2canvas:  { dpi: 192 },
            jsPDF:        { unit: 'cm', format: 'a4', orientation: 'portrait' }
          });

          // CREATE A WINDOW OBJECT.
          // var win = window.open('', '', 'height=700,width=700');
      
          // win.document.write('<html><head>');
          // win.document.write('<title>'+ 'rapports_ventes_' + Date.now() +'</title>');   // <title> FOR PDF HEADER.
          // win.document.write(style);          // ADD STYLE INSIDE THE HEAD TAG.
          // win.document.write('</head>');
          // win.document.write('<body>');
          // win.document.write(content);         // THE TABLE CONTENTS INSIDE THE BODY TAG.
          // win.document.write('</body></html>');
      
          // win.document.close(); 	// CLOSE THE CURRENT WINDOW.
      
          // win.print();  
    
          this.loading = false;
          } else {
            this.loading = false;
            alert("Pas de ventes disponibles.");
          }
          } else {
            this.loading = false;
            alert("Pas de ventes disponibles.");
          }
  }

}
